<template>
  <div class="page-wrapper-header d-flex justify-content-between">
    <div class="page-wrapper-title">
      <p class="text-color-65-gray text-fteen mb-0">
        <span>
          <strong> {{ titleItems[0].count }} </strong>
          {{ titleItems[0].title }},
        </span>
        <span>
          <strong> {{ titleItems[1].count }} </strong> {{ titleItems[1].title }}
        </span>
      </p>
    </div>
    <div class="d-flex">
      <div class="page-sorting-items">
        <span class="me-3 text-color-65-gray">Jahr</span>
        <select
          @change="changeYear"
          v-model="sortYear"
          class="form-control sorting-select"
          style="min-width: 85px; margin-right: 20px"
        >
          <option
            v-for="yearOption in yearOptions"
            :key="yearOption.value"
            :selected="yearOption.isSelected"
            :value="yearOption.value"
          >
            {{ yearOption.label }}
          </option>
        </select>
      </div>
      <button
        class="btn btn-primary text-fteen text-center download-button"
        :class="[
          downloadFilesDisabled ? 'download-disabled disabled' : '',
          downloadAllFilesLoading ? 'opacity-50' : ''
        ]"
        @click="onDownloadClick"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleItems: {
      type: Array,
      default: () => []
    },
    year: {
      type: String,
      default: 'actual'
    },
    filesList: {
      type: Array,
      default: () => []
    },
    downloadAllFilesLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortYear: this.year,
      yearOptions: this.extractYearsFromAttributes(this.filesList)
    }
  },
  computed: {
    downloadFilesDisabled() {
      return !this.filesList.find(item => item.isSelected)
    }
  },
  methods: {
    changeYear() {
      this.$emit('changeYear', this.sortYear)
    },
    onDownloadClick() {
      if (this.downloadAllFilesLoading) return
      this.$emit('onDownloadSelectedClick')
    },
    extractYearsFromAttributes(attributes) {
      const yearRegex = /\b\d{4}\b/g // Regular expression to match 4-digit years
      const uniqueYears = new Set() // Set to store unique years

      // Iterate through each attribute in the array
      attributes.forEach(attribute => {
        // Find all year matches in the attribute
        const matches = attribute.scanprixBlobName.match(yearRegex)

        // If matches are found, add them to the set
        if (matches) {
          matches.forEach(year => {
            uniqueYears.add(parseInt(year))
          })
        }
      })

      // Convert the set to an array and sort it
      const sortedYears = Array.from(uniqueYears).sort((a, b) => b - a)

      const formattedYears = sortedYears.map((year, index) => ({
        value: year.toString(),
        label: year.toString(),
        isSelected: index === 0 // Mark the most recent year as selected
      }))

      // Add the 'all' option at the end of the list
      formattedYears.push({ value: 'all', label: 'Alles' })

      return formattedYears
    }
  }
}
</script>

<style>
.download-button {
  height: 34px;
  size: 14px;
  font-weight: 700;
  width: 90px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0px;
}

.download-disabled {
  border: none;
  background-color: var(--vme-light-gray) !important;
}
</style>
